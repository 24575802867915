.home-page {
  width: 80%;
  height: 100%;
  margin: 0 10%; }
  .home-page .navigation-tabs {
    margin-top: 25px; }
    .home-page .navigation-tabs .tab-hidden {
      display: none; }
  .home-page table td {
    opacity: 0.87;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4a4a4a; }
