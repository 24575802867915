.modal-content {
  position: absolute;
  background: white;
  width: 50%;
  max-height: 50%;
  top: 25%;
  left: 25%;
  padding: 20px;
  overflow: auto;
  border-radius: 6px; }
  .modal-content .abuse-content {
    margin-top: 0;
    text-align: justify;
    color: #4a4a4a; }
  .modal-content .modal-button {
    width: 40px;
    margin-left: calc(100% - 40px);
    border: none;
    padding: 0;
    background: inherit;
    font-size: 14px;
    text-decoration: underline;
    color: #25459f;
    cursor: pointer; }

.abuses-list--page {
  min-width: 600px;
  text-align: right; }
  .abuses-list--page .abuses-table {
    margin-top: 22px; }
    .abuses-list--page .abuses-table th, .abuses-list--page .abuses-table td {
      padding-left: 12px;
      padding-right: 12px; }
    .abuses-list--page .abuses-table thead {
      height: 46px;
      background-color: #f3f3f3; }
      .abuses-list--page .abuses-table thead th {
        width: 65px;
        height: 14px;
        opacity: 0.87;
        font-family: Graphik-Medium, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        color: #4a4a4a; }
        .abuses-list--page .abuses-table thead th.list-actions {
          width: 115px; }
    .abuses-list--page .abuses-table tbody .content {
      position: relative;
      overflow: hidden; }
      .abuses-list--page .abuses-table tbody .content.long .view-more-button {
        display: block; }
      .abuses-list--page .abuses-table tbody .content .abuse-content {
        margin-top: 0;
        margin-bottom: 0;
        overflow: hidden;
        position: relative;
        line-height: 21px;
        max-height: 63px;
        text-align: justify;
        margin-right: -1em;
        padding-right: 1em; }
        .abuses-list--page .abuses-table tbody .content .abuse-content:before {
          content: '...';
          position: absolute;
          right: 1px;
          bottom: 0; }
        .abuses-list--page .abuses-table tbody .content .abuse-content:after {
          content: '';
          position: absolute;
          right: 0;
          width: 1em;
          height: 1em;
          margin-top: 0.2em;
          background: white; }
      .abuses-list--page .abuses-table tbody .content .view-more-button {
        display: none;
        border: none;
        padding: 0;
        background: inherit;
        font-size: 14px;
        text-decoration: underline;
        color: #25459f;
        cursor: pointer;
        font-size: 13px;
        text-decoration: none;
        background: white; }
        .abuses-list--page .abuses-table tbody .content .view-more-button:hover {
          text-decoration: underline; }
    .abuses-list--page .abuses-table tbody .when_posted {
      width: 90px; }
    .abuses-list--page .abuses-table tbody .quantity_of_reports {
      text-align: center; }
    .abuses-list--page .abuses-table tbody .abuse-action {
      min-width: 122px;
      padding-left: 0;
      padding-right: 0;
      text-align: center; }
      .abuses-list--page .abuses-table tbody .abuse-action button {
        border: none;
        padding: 0;
        background: inherit;
        font-size: 14px;
        text-decoration: underline;
        color: #25459f;
        cursor: pointer; }
