@import url(https://fonts.googleapis.com/css?family=Kaushan+Script);
.header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #ebebeb; }
  .header h3 {
    font-family: KaushanScript-Regular, Kaushan Script;
    font-size: 27.4px;
    margin-left: 10%;
    color: #2f219d;
    flex-grow: 1; }
  .header p {
    margin-right: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #4a4a4a; }
  .header button {
    margin-right: 10%;
    padding: 0;
    background-color: inherit;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 900; }

.login-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .login-page .app-logo {
    max-width: 245px; }
  .login-page h5 {
    font-family: Graphik-Semibold, sans-serif;
    margin: 0;
    color: #3d4248;
    font-size: 16px;
    font-weight: 600; }
  .login-page form {
    width: 100%;
    margin-top: 13vh;
    max-width: 346px;
    display: flex;
    flex-direction: column; }
    .login-page form button {
      height: 40px;
      border-radius: 4px; }

.modal-content {
  position: absolute;
  background: white;
  width: 50%;
  max-height: 50%;
  top: 25%;
  left: 25%;
  padding: 20px;
  overflow: auto;
  border-radius: 6px; }
  .modal-content .abuse-content {
    margin-top: 0;
    text-align: justify;
    color: #4a4a4a; }
  .modal-content .modal-button {
    width: 40px;
    margin-left: calc(100% - 40px);
    border: none;
    padding: 0;
    background: inherit;
    font-size: 14px;
    text-decoration: underline;
    color: #25459f;
    cursor: pointer; }

.abuses-list--page {
  min-width: 600px;
  text-align: right; }
  .abuses-list--page .abuses-table {
    margin-top: 22px; }
    .abuses-list--page .abuses-table th, .abuses-list--page .abuses-table td {
      padding-left: 12px;
      padding-right: 12px; }
    .abuses-list--page .abuses-table thead {
      height: 46px;
      background-color: #f3f3f3; }
      .abuses-list--page .abuses-table thead th {
        width: 65px;
        height: 14px;
        opacity: 0.87;
        font-family: Graphik-Medium, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        color: #4a4a4a; }
        .abuses-list--page .abuses-table thead th.list-actions {
          width: 115px; }
    .abuses-list--page .abuses-table tbody .content {
      position: relative;
      overflow: hidden; }
      .abuses-list--page .abuses-table tbody .content.long .view-more-button {
        display: block; }
      .abuses-list--page .abuses-table tbody .content .abuse-content {
        margin-top: 0;
        margin-bottom: 0;
        overflow: hidden;
        position: relative;
        line-height: 21px;
        max-height: 63px;
        text-align: justify;
        margin-right: -1em;
        padding-right: 1em; }
        .abuses-list--page .abuses-table tbody .content .abuse-content:before {
          content: '...';
          position: absolute;
          right: 1px;
          bottom: 0; }
        .abuses-list--page .abuses-table tbody .content .abuse-content:after {
          content: '';
          position: absolute;
          right: 0;
          width: 1em;
          height: 1em;
          margin-top: 0.2em;
          background: white; }
      .abuses-list--page .abuses-table tbody .content .view-more-button {
        display: none;
        border: none;
        padding: 0;
        background: inherit;
        font-size: 14px;
        text-decoration: underline;
        color: #25459f;
        cursor: pointer;
        font-size: 13px;
        text-decoration: none;
        background: white; }
        .abuses-list--page .abuses-table tbody .content .view-more-button:hover {
          text-decoration: underline; }
    .abuses-list--page .abuses-table tbody .when_posted {
      width: 90px; }
    .abuses-list--page .abuses-table tbody .quantity_of_reports {
      text-align: center; }
    .abuses-list--page .abuses-table tbody .abuse-action {
      min-width: 122px;
      padding-left: 0;
      padding-right: 0;
      text-align: center; }
      .abuses-list--page .abuses-table tbody .abuse-action button {
        border: none;
        padding: 0;
        background: inherit;
        font-size: 14px;
        text-decoration: underline;
        color: #25459f;
        cursor: pointer; }

.users-list--page {
  max-width: 632px; }
  .users-list--page .search-wrapper {
    margin-top: 22px;
    display: flex;
    align-items: center; }
    .users-list--page .search-wrapper .no-search-result {
      margin-left: 26px;
      width: 146px;
      font-size: 14px;
      color: #f1988e; }
      .users-list--page .search-wrapper .no-search-result.hidden {
        display: none; }
    .users-list--page .search-wrapper #search-users-textfield {
      min-width: 200px; }
    .users-list--page .search-wrapper .clear-icon.hidden {
      visibility: hidden; }
  .users-list--page .users-table {
    margin-top: 22px; }
    .users-list--page .users-table thead {
      height: 46px;
      background-color: #f3f3f3; }
      .users-list--page .users-table thead th {
        width: 65px;
        height: 14px;
        opacity: 0.87;
        font-family: Graphik-Medium, sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a; }
        .users-list--page .users-table thead th.full_name {
          width: 130px; }
        .users-list--page .users-table thead th.list-actions {
          width: 95px; }
    .users-list--page .users-table .user-action {
      padding-left: 10px;
      padding-right: 10px; }
      .users-list--page .users-table .user-action button {
        border: none;
        background: inherit;
        font-size: 14px;
        text-decoration: underline;
        color: #25459f;
        cursor: pointer; }
    .users-list--page .users-table .warning-action button {
      color: #ce1313; }
    .users-list--page .users-table .approve-action button {
      color: #0ea802; }

.home-page {
  width: 80%;
  height: 100%;
  margin: 0 10%; }
  .home-page .navigation-tabs {
    margin-top: 25px; }
    .home-page .navigation-tabs .tab-hidden {
      display: none; }
  .home-page table td {
    opacity: 0.87;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4a4a4a; }

@font-face {
  font-family: 'Graphik-Regular';
  src: url("/static/fonts/Graphik-Regular.otf") format("opentype"); }

@font-face {
  font-family: 'Graphik-Medium';
  src: url("/static/fonts/Graphik-Medium.otf") format("opentype"); }

@font-face {
  font-family: 'Graphik-Semibold';
  src: url("/static/fonts/Graphik-Semibold.otf") format("opentype"); }

html, body, #root {
  min-width: 100vw;
  min-height: 100vh; }

body {
  margin: 0;
  padding: 0;
  font-family: "Graphik-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

