.users-list--page {
  max-width: 632px; }
  .users-list--page .search-wrapper {
    margin-top: 22px;
    display: flex;
    align-items: center; }
    .users-list--page .search-wrapper .no-search-result {
      margin-left: 26px;
      width: 146px;
      font-size: 14px;
      color: #f1988e; }
      .users-list--page .search-wrapper .no-search-result.hidden {
        display: none; }
    .users-list--page .search-wrapper #search-users-textfield {
      min-width: 200px; }
    .users-list--page .search-wrapper .clear-icon.hidden {
      visibility: hidden; }
  .users-list--page .users-table {
    margin-top: 22px; }
    .users-list--page .users-table thead {
      height: 46px;
      background-color: #f3f3f3; }
      .users-list--page .users-table thead th {
        width: 65px;
        height: 14px;
        opacity: 0.87;
        font-family: Graphik-Medium, sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a; }
        .users-list--page .users-table thead th.full_name {
          width: 130px; }
        .users-list--page .users-table thead th.list-actions {
          width: 95px; }
    .users-list--page .users-table .user-action {
      padding-left: 10px;
      padding-right: 10px; }
      .users-list--page .users-table .user-action button {
        border: none;
        background: inherit;
        font-size: 14px;
        text-decoration: underline;
        color: #25459f;
        cursor: pointer; }
    .users-list--page .users-table .warning-action button {
      color: #ce1313; }
    .users-list--page .users-table .approve-action button {
      color: #0ea802; }
