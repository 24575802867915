@import url("https://fonts.googleapis.com/css?family=Kaushan+Script");
@font-face {
  font-family: 'Graphik-Regular';
  src: url("/static/fonts/Graphik-Regular.otf") format("opentype"); }

@font-face {
  font-family: 'Graphik-Medium';
  src: url("/static/fonts/Graphik-Medium.otf") format("opentype"); }

@font-face {
  font-family: 'Graphik-Semibold';
  src: url("/static/fonts/Graphik-Semibold.otf") format("opentype"); }

html, body, #root {
  min-width: 100vw;
  min-height: 100vh; }

body {
  margin: 0;
  padding: 0;
  font-family: "Graphik-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
