.login-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .login-page .app-logo {
    max-width: 245px; }
  .login-page h5 {
    font-family: Graphik-Semibold, sans-serif;
    margin: 0;
    color: #3d4248;
    font-size: 16px;
    font-weight: 600; }
  .login-page form {
    width: 100%;
    margin-top: 13vh;
    max-width: 346px;
    display: flex;
    flex-direction: column; }
    .login-page form button {
      height: 40px;
      border-radius: 4px; }
