.header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #ebebeb; }
  .header h3 {
    font-family: KaushanScript-Regular, Kaushan Script;
    font-size: 27.4px;
    margin-left: 10%;
    color: #2f219d;
    flex-grow: 1; }
  .header p {
    margin-right: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #4a4a4a; }
  .header button {
    margin-right: 10%;
    padding: 0;
    background-color: inherit;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 900; }
